@charset "utf-8";
#ReviewContainer{
    /*margin: auto;
    padding: 0;
    display: block;*/
    border: 1px solid #00C7D6;
}
#ReviewContainer #ReviewTitle{
    margin: auto;
    padding: 0;
    display: block;
    background-color: #ffffff;
    font-weight: bold;
    color: #153768;
    text-align: center;
}
#ReviewContainer #review_mark{
    margin: auto;
    padding: 0;
    display: block;
}
#ReviewContainer #ReviewList{
    margin: 0;
    padding: 0;
    display: block;
    list-style: none;
    text-align: right;
    width: 100%;
}
#ReviewContainer #ReviewList .ReviewListItem{
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;
    width: 100%;
    transition: .3s;
}
#ReviewContainer #ReviewList .ReviewListItemNotView{
    display: none;
}
#ReviewContainer #ReviewList .ReviewListItemView{
    display: block;
    position: relative;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -70%);
}
#ReviewContainer #ReviewList .ReviewListItem .ReviewListItemParagraph{
    margin: 0;
    padding: 0;
    width: 100%;
    color: #808080;
}
#ReviewContainer #ReviewList .ReviewListItem .ReviewListItemStarList{
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
    text-align: center;
    list-style: none;
}
#ReviewContainer #ReviewList .ReviewListItem .ReviewListItemStarList .ReviewListItemStarListItem{
    margin: 0;
    padding: 0 3px;
    display: inline-block;
    vertical-align: middle;
    width: 15px;
}
#ReviewContainer #ReviewList .ReviewListItem .ReviewListItemStarList .ReviewListItemStarListItem .ReviewListItemStarListItemImage{
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
}
#ReviewContainer #ReviewList .ReviewListItem .ReviewListItemAuthor{
    margin: 0;
    padding: 0;
    width: 100%;
    font-weight: bold;
    color: #808080;
}
#ReviewContainer #ReviewButtonsList{
    margin: 0;
    padding: 0;
    display: block;
    list-style: none;
    text-align: right;
    width: 100%;
}
#ReviewContainer #ReviewButtonsList .ReviewButtonsListItem{
    margin: 0;
    padding: 0;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
}
#ReviewContainer #ReviewButtonsList .ReviewButtonsListItem .ReviewButtonsListItemImage{
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
}
@media screen and (min-width: 701px) {
    /*#ReviewContainer{
        width: 60%;
        min-width: 500px;
        height: 350px;
        padding: 10px;
    }
    #ReviewContainer #ReviewTitle{
        padding: 1vh;
        margin-top: -30px;
        font-size: 1.7em;
        width: 200px;
    }
    #ReviewContainer #review_mark{
        width: 60px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    #ReviewContainer #ReviewList{
        height: 220px;
    }
    /*#ReviewContainer #ReviewList .ReviewListItem{
        
    }
    #ReviewContainer #ReviewList .ReviewListItem .ReviewListItemStarList{
        padding: 1vh 0;
    }
    /*#ReviewContainer #ReviewList .ReviewListItem .ReviewListItemStarList .ReviewListItemStarListItem{
        
    }
    #ReviewContainer #ReviewList .ReviewListItem .ReviewListItemStarList .ReviewListItemStarListItem .ReviewListItemStarListItemImage{
        
    }
    #ReviewContainer #ReviewList .ReviewListItem .ReviewListItemAuthor{
        font-size: 1.2em;
    }
    #ReviewContainer #ReviewList .ReviewListItem .ReviewListItemParagraph{
        font-size: 1.2em;
    }
    /*#ReviewContainer #ReviewButtonsList{
        
    }
    #ReviewContainer #ReviewButtonsList .ReviewButtonsListItem{
        padding: 0 3px;
        width: 20px;
    }*/
}
@media screen and (max-width: 700px) {
    /*#ReviewContainer{
        width: 80%;
        height: 350px;
        padding: 10px;
    }
    #ReviewContainer #ReviewTitle{
        padding: 1vh;
        margin-top: -30px;
        font-size: 1.7em;
        width: 200px;
    }
    #ReviewContainer #review_mark{
        width: 40px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    #ReviewContainer #ReviewList{
        height: 220px;
    }
    /*#ReviewContainer #ReviewList .ReviewListItem{
        
    }
    #ReviewContainer #ReviewList .ReviewListItem .ReviewListItemStarList{
        padding: 1vh 0;
    }
    /*#ReviewContainer #ReviewList .ReviewListItem .ReviewListItemStarList .ReviewListItemStarListItem{
        
    }
    #ReviewContainer #ReviewList .ReviewListItem .ReviewListItemStarList .ReviewListItemStarListItem .ReviewListItemStarListItemImage{
        
    }
    #ReviewContainer #ReviewList .ReviewListItem .ReviewListItemAuthor{
        font-size: 1.2em;
    }
    #ReviewContainer #ReviewList .ReviewListItem .ReviewListItemParagraph{
        font-size: 1.2em;
    }
    /*#ReviewContainer #ReviewButtonsList{
        
    }
    #ReviewContainer #ReviewButtonsList .ReviewButtonsListItem{
        padding: 0 3px;
        width: 20px;
    }*/
}
